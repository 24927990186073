import React, { useEffect, useState } from 'react'
import ApiNames from '../../Constants/ApiNames';
import Axios from '../../Utils/AxiosConfig';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Newsletter from '../Newsletter/Newsletter';
import { Helmet } from "react-helmet";
const BlogData = () => {
  let navigation = useNavigate();
  const { id } = useParams();
  const [blogData, setblogData] = useState([]);
  const [blogsData, setBlogsData] = useState([]);



  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    blogcontent(id)
  },[id]);


  function blogcontent(id){
    const url = ApiNames.getBlogData + id;
    const body = {
      _id: id,
    };

    Axios.get(url, body)
      .then((res) => {
        setblogData(res.data[0]);
        

      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    const getBlogs = async () => {
      try {
        let api = ApiNames.getBlogs
        const response = await Axios.get(
          `${api}`
        );
        setBlogsData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getBlogs();
  }, []);

  function gotoBLogByid(data){
    navigation(`/bloginfo/${data._id}`)
  }

  return (
    <>
      <Helmet>
        <title>
          Blog Info
        </title>
      </Helmet>
      <div className="allblogs-data allblogs-data1">
        <div className="container">
          <div className="desort-board">
            <div className="row justify-content-center">
              <div className=" col-md-6">
                <div className="desort-card">
                  <div className="card desort-card-item">
                    <div className="card-image-top">
                      <img
                        src={blogData?.image}
                        className="desort-img-top"
                        alt="..."
                      />
                    </div>
                    <div className="card-body">
                      <p className="desort-whitten-para">{blogData?.date}</p>
                      <h1 className="desort-compounding-head">
                        {blogData?.title}
                      </h1>
                      <p className="desort-text-whitten">
                        {blogData?.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="inventions-section">
          <div className="container">
            <div className="latestinvention-topsection">
              <ul className="latestinvention-ul">
                <li className="latestinvention-li">
                  <div className="latestinvention-content">
                    <h3 className="latestinvention-title">Blog</h3>
                    <p className="latestinvention-para">
                    Our commitment to research and development means we're always on the cusp of the next eco-friendly breakthrough.
                    </p>
                  </div>
                </li>
                <li className="latestinvention-li">
                  <NavLink className="viewall-btn" to="/allblogs">
                    View all{" "}
                        <i className="fas fa-arrow-right right-icon"></i>
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="row">
            {blogsData.slice(0, 4).map((products, index) => (
                <div key={index} className="col-md-3">
                  <div className="card invention-items" onClick={()=>gotoBLogByid(products)}>
                    <div className="inventionitem-image">
                      <img
                        src={products?.image}
                        className="card-img-top image-data"
                        alt="Card"
                      />
                    </div>
                    <div className="card-body invention-body">
                      <ul className="invention-card-ul">
                        <li className="invention-card-li">
                          <p className="invention-card-title">{products?.title.slice(0,60)}</p>
                        </li>
                        <li className="invention-card-li">
                          <i className="fas fa-arrow-right right-icon"></i>
                        </li>
                      </ul>
                   
                        <p className="invention-card-para">
                          {products?.description.slice(0,120)}
                        </p>
                 
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Newsletter />
  

    </>
  )
}

export default BlogData