import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

const Ourproducts = () => {
  const [activeImage, setActiveImage] = useState(0);
  useEffect(() => {
      window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
      });
  }, []);

  const images = [
      "/images/ourproducts/products-m/image-1.webp",
      "/images/ourproducts/products-m/image-2.webp",
      "/images/ourproducts/products-m/image-3.webp",
      "/images/ourproducts/products-m/image-4.webp",
      "/images/ourproducts/products-m/image-5.webp",
      "/images/ourproducts/products-m/image-6.webp",
      "/images/ourproducts/products-m/image-7.webp",
      "/images/ourproducts/products-m/image-8.webp",
      "/images/ourproducts/products-m/image-9.webp",
      "/images/ourproducts/products-m/image-10.webp",
      "/images/ourproducts/products-m/image-11.webp",
      "/images/ourproducts/products-m/image-12.webp",
      "/images/ourproducts/products-m/image-13.webp",
      "/images/ourproducts/products-m/image-14.webp",
      "/images/ourproducts/products-m/image-15.webp",
      "/images/ourproducts/products-m/image-16.webp",
      "/images/ourproducts/products-m/image-17.webp",
   
  ];

  const contents = [
      "Discover our premium acrylic sheets, offering exceptional clarity, durability, and versatility. Ideal for signage, displays, and architectural applications. Cast and extruded options available to suit your diverse needs.",
      "Explore our lightweight and durable aluminium composite panels, perfect for exterior cladding, signage, and interior design projects",
      "Experience our innovative LED solutions, including drivers, modules, strips, and panels. Transform spaces with energy-efficient lighting that delivers superior performance and aesthetics",
      "Elevate interiors with our range of solid surface materials, made in Korea from MMA-based components. Discover seamless designs for countertops, furniture, and architectural elements.",
      "Explore our wide range of decorative laminates, offering durability and style for furniture, countertops, and interior surfaces",
      "Discover our premium RMD wall panels, combining aesthetics with functionality. Ideal for creating stunning feature walls and durable partitions.",
      "Browse our selection of high-quality adhesive tapes for various applications, including bonding, sealing, and mounting",
      "Enhance communication and engagement with our cutting-edge digital signage solutions. Deliver dynamic content and messages with impact",
      "Experience the strength and versatility of our polycarbonate sheets, suitable for applications requiring impact resistance and optical clarity",
      "Choose from our range of adhesive solutions designed for bonding different materials securely and reliably",
      "Transform surfaces and create vibrant displays with our durable and versatile color vinyl films. Ideal for signage, decals, and decorative applications",
      "Unlock creative possibilities with our premium print media, optimized for vivid and long-lasting prints across various printing technologies",
      "Explore our PET sheets, offering clarity, impact resistance, and recyclability. Ideal for packaging, signage, and protective barriers",
      "Discover our ABS sheets, known for their toughness, impact resistance, and machinability. Suitable for automotive parts, prototypes, and displays",
      "Complete your signage projects with our comprehensive range of accessories and decorative elements, ensuring professional and eye-catching results",
      "Create sleek and durable channel letter signs with our Channelium profiles, designed for precision and ease of installation",
      "Promote your brand or message with our portable and versatile roll-up stands, ideal for trade shows, events, and retail displays",
  ];
  const header = [
      "Acrylic Sheets",
      "Aluminium Composite Panels",
      "LED Drivers, Modules, Strips, and Panels",
      "Solid Surface",
      "Laminates",
      "PP Wall Decor Panels",
      "Adhesive Tapes",
      "Digital Signage",
      "Polycarbonate",
      "Adhesive",
      "Color Vinyl",
      "Print media",
      "PET Sheets",
      "ABS Sheets",
      "Sign accessories and decorations",
      "Channelium",
      "Roll up Stands",
    
  ];
  const subHeader = [
      "Flexibility and creativity in every design",
      "Durable material for creative solutions for buildings and signage purpose",
      "The comprehensive lighting solution for signage use.",
      "An excellent and eco-friendly choice for kitchens and domestic use",
      "Eco-friendly material for residential and commercial use",
      "Temperature and fire-resistant panels for all climates, with noise-reduction properties",
      "Duct, reflective, masking, and double-sided tapes for bonding, sealing, and insulating different surfaces and materials.",
      "High-quality vinyl wrappings and boards for signage purposes",
      "High-grade, recyclable thermoplastics for residential and commercial use.",
      "Solvent-free adhesives made of 100% cyanoacrylate monomer especially thickened to meet bonding requirements for a wide variety of materials.",
      "Achieve various colorful effects in outdoor signage",
      "Get your brand ahead and be seen!",
      "Clear, lightweight material for construction and signage",
      "Durable, recyclable, impact-resistant thermoplastic with great chemical resistance properties",
      "Creative and aesthetic signage solutions for all",
      "Lettering solution for creative signage",
      "Portable banners, especially for outdoor advertising campaigns",
  ];

  const handleImageClick = (index) => {
      setActiveImage(index);
  };

  const handlePrev = () => {
      setActiveImage(
          (prevActiveImage) => (prevActiveImage - 1 + images.length) % images.length
      );
  };

  const handleNext = () => {
      setActiveImage((prevActiveImage) => (prevActiveImage + 1) % images.length);
  };
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>
        Explore Our Innovative Product Range
        </title>
      </Helmet>
    
      <div className="OurProducts-section">
        <div className="container">
          <div className='ourporducts-hero'>
            <div className="maintitle-section">
              <h3 className="main-title">Explore our comprehensive range of innovative products</h3>
              <p className="main-subtitle">
                Discover revolutionary materials designed to optimize efficiency and durability. Just Right’s commitment to eco-friendly practices means that materials reduce environmental impact while maintaining high performance standards.
              </p>
              <p className="main-subtitle">
                All of our products are rigorously tested to meet industry standards, ensuring reliability and longevity.
              </p>
            </div>
          </div>
          <div className="OurProducts-section2">
            <div className="row h-100">
              <div className="col-md-6">
                <div className="Oursection2-left">
                  <div className="nav flex-column nav-pills me-3" id="v-pills-tab1" role="tablist" aria-orientation="vertical">
                    <button
                      className={`nav-link custmnav-link active`}
                      id="v-pills-Acrylic-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-Acrylic"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-Acrylic"
                    >
                      <h3 className="custmnav-head">rightACRYLIC</h3>
                      <p className="custmnav-subhead">Flexibility and creativity in every design</p>
                      <p className="custmnav-txt">Discover our premium acrylic sheets, offering exceptional clarity, durability, and versatility. Ideal for signage, displays, and architectural applications. Cast and extruded options available to suit your diverse needs.</p>
                    </button>
                    <button
                      className={`nav-link custmnav-link `}
                      id="v-pills-Aluminium-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-Aluminium"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-Aluminium"
                    >
                      <h3 className="custmnav-head">rightBOND</h3>
                      <p className="custmnav-subhead">Durable material for creative solutions for buildings and signage purpose</p>
                      <p className="custmnav-txt">Explore our lightweight and durable aluminium composite panels, perfect for exterior cladding, signage, and interior design projects.</p>
                    </button>
                    <button
                      className={`nav-link custmnav-link `}
                      id="v-pills-Strips-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-Strips"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-Strips"

                    >
                      <h3 className="custmnav-head">LED Drivers, Modules, Strips, and Panels</h3>
                      <p className="custmnav-subhead">The comprehensive lighting solution for signage use.</p>
                      <p className="custmnav-txt">Experience our innovative LED solutions, including drivers, modules, strips, and panels. Transform spaces with energy-efficient lighting that delivers superior performance and aesthetics.</p>
                    </button>
                    <button
                      className={`nav-link custmnav-link `}
                      id="v-pills-Solid-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-Solid"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-Solid"
                    >
                      <h3 className="custmnav-head">Solid Surface</h3>
                      <p className="custmnav-subhead">An excellent and eco-friendly choice for kitchens and domestic use</p>
                      <p className="custmnav-txt">Elevate interiors with our range of solid surface materials, made in Korea from MMA-based components. Discover seamless designs for countertops, furniture, and architectural elements.</p>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="Oursection2-right">
                  <div className="tab-content tabcontent-new" id="v-pills-tab1Content">
                    <div
                      className={`tab-pane tab-pane-new fade show active`}
                      id="v-pills-Acrylic"
                      role="tabpanel"
                      aria-labelledby="v-pills-Acrylic-tab"
                    >
                      <img className="Oursection2right-im" src="/images/ourproducts/products/1.webp" alt="Acrylic Sheets" />
                    </div>
                    <div
                      className={`tab-pane tab-pane-new fade `}
                      id="v-pills-Aluminium"
                      role="tabpanel"
                      aria-labelledby="v-pills-Aluminium-tab"
                    >
                      <img className="Oursection2right-im" src="/images/ourproducts/products/2.webp" alt="Aluminium Composite Panels" />
                    </div>
                    <div
                      className={`tab-pane tab-pane-new fade `}
                      id="v-pills-Strips"
                      role="tabpanel"
                      aria-labelledby="v-pills-Strips-tab"
                    >
                      <img className="Oursection2right-im" src="/images/ourproducts/products/3.webp" alt="LED Strips" />
                    </div>
                    <div
                      className={`tab-pane tab-pane-new fade`}
                      id="v-pills-Solid"
                      role="tabpanel"
                      aria-labelledby="v-pills-Solid-tab"
                    >
                      <img className="Oursection2right-im" src="/images/ourproducts/products/4.webp" alt="Solid Surface" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="OurProducts-section2">
            <div className="row h-100">
              <div className="col-md-6">
                <div className="Oursection2-right">
                  <div className="tab-content tabcontent-new" id="v-pills-tab2Content">
                    <div
                      className={`tab-pane tab-pane-new fade show active`}
                      id="v-pills-Acrylic2"
                      role="tabpanel"
                      aria-labelledby="v-pills-Acrylic2-tab"
                    >
                      <img className="Oursection2right-im" src="/images/ourproducts/products/5.webp" alt="Acrylic Sheets" />
                    </div>
                    <div
                      className={`tab-pane tab-pane-new fade `}
                      id="v-pills-Aluminium2"
                      role="tabpanel"
                      aria-labelledby="v-pills-Aluminium2-tab"
                    >
                      <img className="Oursection2right-im" src="/images/ourproducts/products/6.webp" alt="Aluminium Composite Panels" />
                    </div>
                    <div
                      className={`tab-pane tab-pane-new fade `}
                      id="v-pills-Strips2"
                      role="tabpanel"
                      aria-labelledby="v-pills-Strips2-tab"
                    >
                      <img className="Oursection2right-im" src="/images/ourproducts/products/7.webp" alt="LED Strips" />
                    </div>
                    <div
                      className={`tab-pane tab-pane-new fade `}
                      id="v-pills-Solid2"
                      role="tabpanel"
                      aria-labelledby="v-pills-Solid2-tab"
                    >
                      <img className="Oursection2right-im" src="/images/ourproducts/products/8.webp" alt="Solid Surface" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="Oursection2-left">
                  <div className="nav flex-column nav-pills me-3" id="v-pills-tab2" role="tablist" aria-orientation="vertical">
                    <button
                      className={`nav-link custmnav-link active`}
                      id="v-pills-Acrylic2-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-Acrylic2"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-Acrylic2"

                    >
                      <h3 className="custmnav-head">rightLAM</h3>
                      <p className="custmnav-subhead">Eco-friendly material for residential and commercial use</p>
                      <p className="custmnav-txt">Explore our wide range of decorative laminates, offering durability and style for furniture, countertops, and interior surfaces</p>
                    </button>
                    <button
                      className={`nav-link custmnav-link`}
                      id="v-pills-Aluminium2-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-Aluminium2"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-Aluminium2"

                    >
                      <h3 className="custmnav-head">PP Wall Decor Panels</h3>
                      <p className="custmnav-subhead">Temperature and fire-resistant panels for all climates, with noise-reduction properties</p>
                      <p className="custmnav-txt">Discover our premium RMD wall panels, combining aesthetics with functionality. Ideal for creating stunning feature walls and durable partitions.</p>
                    </button>
                    <button
                      className={`nav-link custmnav-link `}
                      id="v-pills-Strips2-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-Strips2"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-Strips2"

                    >
                      <h3 className="custmnav-head">rightTAPES</h3>
                      <p className="custmnav-subhead">Duct, reflective, masking, and double-sided tapes for bonding, sealing, and insulating different surfaces and materials.</p>
                      <p className="custmnav-txt">Browse our selection of high-quality adhesive tapes for various applications, including bonding, sealing, and mounting</p>
                    </button>
                    <button
                      className={`nav-link custmnav-link `}
                      id="v-pills-Solid2-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-Solid2"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-Solid2"
                    >
                      <h3 className="custmnav-head">Digital Signage</h3>
                      <p className="custmnav-subhead">High-quality vinyl wrappings and boards for signage purposes</p>
                      <p className="custmnav-txt">Enhance communication and engagement with our cutting-edge digital signage solutions. Deliver dynamic content and messages with impact</p>
                    </button>
                  </div>
                </div>
              </div>

            </div>

          </div>
          <div className="OurProducts-section2">
            <div className="row h-100">
              <div className="col-md-6">
                <div className="Oursection2-left">
                  <div className="nav flex-column nav-pills me-3" id="v-pills-tab3" role="tablist" aria-orientation="vertical">
                    <button className="nav-link custmnav-link active" id="v-pills-Polycarbonate-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Polycarbonate" type="button" role="tab" aria-controls="v-pills-Polycarbonate" aria-selected="true">
                      <h3 className="custmnav-head">rightPC</h3>
                      <p className="custmnav-subhead">High-grade, recyclable thermoplastics for residential and commercial use.</p>
                      <p className="custmnav-txt">Experience the strength and versatility of our polycarbonate sheets, suitable for applications requiring impact resistance and optical clarity</p>
                    </button>
                    <button className="nav-link custmnav-link" id="v-pills-Adhesive2-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Adhesive2" type="button" role="tab" aria-controls="v-pills-Adhesive2" aria-selected="false">
                      <h3 className="custmnav-head">rightGLUE</h3>
                      <p className="custmnav-subhead">Solvent-free adhesives made of 100% cyanoacrylate monomer especially thickened to meet bonding requirements for a wide variety of materials.</p>
                      <p className="custmnav-txt">Choose from our range of adhesive solutions designed for bonding different materials securely and reliably</p>
                    </button>
                    <button className="nav-link custmnav-link" id="v-pills-Color-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Color" type="button" role="tab" aria-controls="v-pills-Color" aria-selected="false">
                      <h3 className="custmnav-head">rightCAL</h3>
                      <p className="custmnav-subhead">Achieve various colorful effects in outdoor signage</p>
                      <p className="custmnav-txt">Transform surfaces and create vibrant displays with our durable and versatile color vinyl films. Ideal for signage, decals, and decorative applications</p>
                    </button>
                    <button className="nav-link custmnav-link" id="v-pills-Print-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Print" type="button" role="tab" aria-controls="v-pills-Print" aria-selected="false">
                      <h3 className="custmnav-head">rightGRAPHICS</h3>
                      <p className="custmnav-subhead">Get your brand ahead and be seen!</p>
                      <p className="custmnav-txt">Unlock creative possibilities with our premium print media, optimized for vivid and long-lasting prints across various printing technologies</p>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="Oursection2-right">
                  <div className="tab-content tabcontent-new" id="v-pills-tab3Content">
                    <div className="tab-pane tab-pane-new fade show active" id="v-pills-Polycarbonate" role="tabpanel" aria-labelledby="v-pills-Polycarbonate-tab">
                      <img className="Oursection2right-im" src="/images/ourproducts/products/9.webp" alt='no-data' />
                    </div>
                    <div className="tab-pane tab-pane-new fade" id="v-pills-Adhesive2" role="tabpanel" aria-labelledby="v-pills-Adhesive2-tab">
                      <img className="Oursection2right-im" src="/images/ourproducts/products/10.webp" alt='a' />
                    </div>
                    <div className="tab-pane tab-pane-new fade" id="v-pills-Color" role="tabpanel" aria-labelledby="v-pills-Color-tab">
                      <img className="Oursection2right-im" src="/images/ourproducts/products/11.webp" alt='b' />
                    </div>
                    <div className="tab-pane tab-pane-new fade" id="v-pills-Print" role="tabpanel" aria-labelledby="v-pills-Print-tab">
                      <img className="Oursection2right-im" src="/images/ourproducts/products/12.webp" alt='c' />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="OurProducts-section2">
            <div className="row h-100">
              <div className="col-md-6">
                <div className="Oursection2-right Oursection3-left">
                  <div className="tab-content tabcontent-new" id="v-pills-tab4Content">
                    <div className="tab-pane tab-pane-new fade show active" id="v-pills-PET" role="tabpanel" aria-labelledby="v-pills-PET-tab">
                      <img className="Oursection2right-im Oursection3left-im" src="/images/ourproducts/products/13.webp" alt='no-data' />
                    </div>
                    <div className="tab-pane tab-pane-new fade" id="v-pills-ABS" role="tabpanel" aria-labelledby="v-pills-ABS-tab">
                      <img className="Oursection2right-im" src="/images/ourproducts/products/14.webp" alt='no-data' />
                    </div>
                    <div className="tab-pane tab-pane-new fade" id="v-pills-Sign" role="tabpanel" aria-labelledby="v-pills-Sign-tab">
                      <img className="Oursection2right-im" src="/images/ourproducts/products/15.webp" alt='no-data' />
                    </div>
                    <div className="tab-pane tab-pane-new fade" id="v-pills-Channelium" role="tabpanel" aria-labelledby="v-pills-Channelium-tab">
                      <img className="Oursection2right-im" src="/images/ourproducts/products/16.webp" alt='no-data' />
                    </div>
                    <div className="tab-pane tab-pane-new fade" id="v-pills-Roll" role="tabpanel" aria-labelledby="v-pills-Roll-tab">
                      <img className="Oursection2right-im" src="/images/ourproducts/products/17.webp" alt='no-data' />
                    </div>
                  </div>

                </div>
              </div>
              <div className="col-md-6">
                <div className="Oursection2-left">
                  <div className="nav flex-column nav-pills me-3" id="v-pills-tab4" role="tablist" aria-orientation="vertical">
                    <button className="nav-link custmnav-link active" id="v-pills-PET-tab" data-bs-toggle="pill" data-bs-target="#v-pills-PET" type="button" role="tab" aria-controls="v-pills-PET" aria-selected="true">
                      <h3 className="custmnav-head">rightPET</h3>
                      <p className="custmnav-subhead">Clear, lightweight material for construction and signage</p>
                      <p className="custmnav-txt">Explore our PET sheets, offering clarity, impact resistance, and recyclability. Ideal for packaging, signage, and protective barriers</p>
                    </button>
                    <button className="nav-link custmnav-link" id="v-pills-ABS-tab" data-bs-toggle="pill" data-bs-target="#v-pills-ABS" type="button" role="tab" aria-controls="v-pills-ABS" aria-selected="false">
                      <h3 className="custmnav-head">rightABS</h3>
                      <p className="custmnav-subhead">Durable, recyclable, impact-resistant thermoplastic with great chemical resistance properties</p>
                      <p className="custmnav-txt">Discover our ABS sheets, known for their toughness, impact resistance, and machinability. Suitable for automotive parts, prototypes, and displays</p>
                    </button>
                    <button className="nav-link custmnav-link" id="v-pills-Sign-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Sign" type="button" role="tab" aria-controls="v-pills-Sign" aria-selected="false">
                      <h3 className="custmnav-head">Sign accessories and decorations</h3>
                      <p className="custmnav-subhead">Creative and aesthetic signage solutions for all</p>
                      <p className="custmnav-txt">Complete your signage projects with our comprehensive range of accessories and decorative elements, ensuring professional and eye-catching results</p>
                    </button>
                    <button className="nav-link custmnav-link" id="v-pills-Channelium-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Channelium" type="button" role="tab" aria-controls="v-pills-Channelium" aria-selected="false">
                      <h3 className="custmnav-head">rightCHANNELIUM</h3>
                      <p className="custmnav-subhead">Lettering solution for creative signage</p>
                      <p className="custmnav-txt">Create sleek and durable channel letter signs with our Channelium profiles, designed for precision and ease of installation</p>
                    </button>
                    <button className="nav-link custmnav-link" id="v-pills-Roll-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Roll" type="button" role="tab" aria-controls="v-pills-Roll" aria-selected="false">
                      <h3 className="custmnav-head">Roll up Stands</h3>
                      <p className="custmnav-subhead">Portable banners, especially for outdoor advertising campaigns</p>
                      <p className="custmnav-txt">Promote your brand or message with our portable and versatile roll-up stands, ideal for trade shows, events, and retail displays</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-topproducts d-md-none">
            <Swiper
              modules={[Navigation]}
              slidesPerView={1}
              autoplay={false}
              loop={true}
              navigation={true}
            >
              {images.map((src, index) => (
                <SwiperSlide key={index}>
                  <div className="responsive-swipertop">
                  <div className="content2-box">
                      <div>
                        <h1 className="box2-headr">{header[index]}</h1>
                        <h1 className="box2-paranew">{subHeader[index]}</h1>
                        <p className="box2-para">{contents[index]}</p>
                      </div>
                    </div>
                    <div className='products-images-list'>
                    <img className="imgess" src={src} alt={`image-${index}`} />
                    </div>
                   
                 
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  )
}

export default Ourproducts