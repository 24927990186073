import React, { useState, useEffect } from 'react';
import ApiNames from '../../Constants/ApiNames';
import Axios from '../../Utils/AxiosConfig';
import toastr from 'toastr';
import 'toastr/build/toastr.css';
import {
  showSuccessToast,
  showErrorToast,
} from '../../Utils/toastrService';

const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const handleSubscribe = async () => {
    if (!email) {
      setMessage('Please enter a valid email address.');
      return;
    }

    setIsLoading(true);
    setMessage('');

    try {
      const url = ApiNames.Subscribe_Newsletter
      const response = await Axios.post(url, { email });
      showSuccessToast(response.data.message);
      setEmail("")
    } catch (error) {
      
      showErrorToast(error.response?.data?.message);

    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="subscribe-section">
      <div className="container">
        <h2 className="getway-ToSuccess">Sign up for our newsletter</h2>
        <p className="newsletter-para">
          Be the first to know about releases and industry news and insights.
        </p>
        <div className="email-subscribe-top">
          <div className="email-input">
            <input
              type="email"
              className="your-email-address form-control"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="btnEdit">
            <button
              className="newsletter-subscribe btn btn-primary"
              onClick={handleSubscribe}
              disabled={isLoading}
            >
              {isLoading ? 'Subscribing...' : 'Subscribe'}
              <i className="fa-solid fa-arrow-right arow-right"></i>
            </button>
          </div>
        </div>
        {message && <p className="newsletter-message">{message}</p>}
      </div>
    </div>
  );
};

export default Newsletter;
