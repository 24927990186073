import React, { useEffect } from 'react'
import Newsletter from '../Newsletter/Newsletter'
import { Helmet } from "react-helmet";
import { NavLink } from 'react-router-dom';


const Faqpage = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>
          FAQs
        </title>
      </Helmet>
      <div className='faq-content'>
        <div className="faqhero-section">
          <div className="container">
            <div className="contact-header">
              <h1 className="contact-head">FAQs</h1>
              <p className="contact-teampara">
                Need something cleared up? Here are our most frequently asked
                questions.
              </p>
              {/* <div className="question-information">
                <i className="fas fa-search question-search-icon"></i>
                <input type="search" placeholder="Search" className="question-search" />
              </div> */}
            </div>
          </div>
        </div>

        <div className='faqquestion-sections'>
          <div className="container">
            <div className="product-faq">
              <div className="row">
                <div className="col-md-4">
                  <div className="teamheader">
                    <h2 className="teamheadname">FAQs</h2>
                    <p className="teampara">
                      Everything you need to know about the product and billing. Can’t
                      find the answer you’re looking for? Please chat to our team.
                    </p>
                  </div>

                  <div
                    className="nav nav-pills productbuttons"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <button
                      className="nav-link productbtn active"
                      id="v-pills-product-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-product"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-product"
                      aria-selected="true"
                    >
                      Product Inquiries
                    </button>
                    <button
                      className="nav-link productbtn"
                      id="v-pills-sustainable-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-sustainable"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-sustainable"
                      aria-selected="false"
                    >
                      Sustainability Questions
                    </button>
                    <button
                      className="nav-link productbtn"
                      id="v-pills-ordering-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-ordering"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-ordering"
                      aria-selected="false"
                    >
                      Ordering & Shipping
                    </button>
                    <button
                      className="nav-link productbtn"
                      id="v-pills-warrenty-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-warrenty"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-warrenty"
                      aria-selected="false"
                    >
                      Warranty & Returns
                    </button>
                    <button
                      className="nav-link productbtn"
                      id="v-pills-technical-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-technical"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-technical"
                      aria-selected="false"
                    >
                      Technical Support
                    </button>
                  </div>
                </div>

                <div className="col-md-8">
                  <div className="rightproduct-questions">
                    <div className="tab-content rightquestion" id="v-pills-tabContent">
                      <div
                        className="tab-pane fade show rightquestion-product active"
                        id="v-pills-product"
                        role="tabpanel"
                        aria-labelledby="v-pills-product-tab"
                      >

                        <div
                          className="accordion question-items-accordion"
                          id="product-question"
                        >
                          <div className="accordion-item question-content-items">
                            <div className="question-content-right">
                              <h2 className="accordion-header" id="headingOne">
                                <button
                                  className="accordion-button question-buttons-item"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseOne"
                                  aria-expanded="true"
                                  aria-controls="collapseOne"
                                >
                                  What materials are used in Justright JR products?
                                </button>
                              </h2>
                              <div
                                id="collapseOne"
                                className="accordion-collapse collapse show collapse-button-content"
                                aria-labelledby="headingOne"
                                data-bs-parent="#product-question"
                              >
                                <div className="accordion-body content-body">
                                  <p className="content-body-para">
                                    Our products are made from high-quality, sustainable plastics that are designed to minimize environmental impact.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <div className="accordion-item question-content-items">
                            <div className="question-content-right">
                              <h2 className="accordion-header" id="headingTwo">
                                <button
                                  className="accordion-button collapsed question-buttons-item"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="collapseTwo"
                                >
                                  Are Justright JR products BPA-free?
                                </button>
                              </h2>
                              <div
                                id="collapseTwo"
                                className="accordion-collapse collapse collapse-button-content"
                                aria-labelledby="headingTwo"
                                data-bs-parent="#product-question"
                              >
                                <div className="accordion-body content-body">
                                  <p className="content-body-para">
                                    Yes, all Justright JR products are 100% BPA-free and safe for use.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div> */}

                          <div className="accordion-item question-content-items">
                            <div className="question-content-right">
                              <h2 className="accordion-header" id="headingThree">
                                <button
                                  className="accordion-button collapsed question-buttons-item"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseThree"
                                  aria-expanded="false"
                                  aria-controls="collapseThree"
                                >
                                  How do I determine the right Justright JR product for my needs?
                                </button>
                              </h2>
                              <div
                                id="collapseThree"
                                className="accordion-collapse collapse collapse-button-content"
                                aria-labelledby="headingThree"
                                data-bs-parent="#product-question"
                              >
                                <div className="accordion-body content-body">
                                  <p className="content-body-para">
                                    You can refer to our product guide on the website or contact our customer service team for personalized recommendations.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item question-content-items">
                            <div className="question-content-right">
                              <h2 className="accordion-header" id="headingFour">
                                <button
                                  className="accordion-button collapsed question-buttons-item"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseFour"
                                  aria-expanded="false"
                                  aria-controls="collapseFour"
                                >
                                  Where can I find the product specifications for Justright JR items?
                                </button>
                              </h2>
                              <div
                                id="collapseFour"
                                className="accordion-collapse collapse collapse-button-content"
                                aria-labelledby="headingFour"
                                data-bs-parent="#product-question"
                              >
                                <div className="accordion-body content-body">
                                  <p className="content-body-para">
                                    Product specifications are available on our website under each product listing. You can also request detailed information via our contact form.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="tab-pane fade rightquestion-product"
                        id="v-pills-sustainable"
                        role="tabpanel"
                        aria-labelledby="v-pills-sustainable-tab"
                      >

                        <div
                          className="accordion question-items-accordion"
                          id="sustainable-question"
                        >
                          <div className="accordion-item question-content-items">
                            <div className="question-content-right">
                              <h2 className="accordion-header" id="headingOne">
                                <button
                                  className="accordion-button question-buttons-item"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseOne"
                                  aria-expanded="true"
                                  aria-controls="collapseOne"
                                >
                                  How does Justright JR ensure sustainable manufacturing?
                                </button>
                              </h2>
                              <div
                                id="collapseOne"
                                className="accordion-collapse collapse show collapse-button-content"
                                aria-labelledby="headingOne"
                                data-bs-parent="#sustainable-question"
                              >
                                <div className="accordion-body content-body">
                                  <p className="content-body-para">
                                    We use eco-friendly materials and energy-efficient processes to reduce our carbon footprint and ensure sustainability.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item question-content-items">
                            <div className="question-content-right">
                              <h2 className="accordion-header" id="headingTwo">
                                <button
                                  className="accordion-button collapsed question-buttons-item"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="collapseTwo"
                                >
                                  What initiatives has Justright JR taken to become carbon neutral?
                                </button>
                              </h2>
                              <div
                                id="collapseTwo"
                                className="accordion-collapse collapse collapse-button-content"
                                aria-labelledby="headingTwo"
                                data-bs-parent="#sustainable-question"
                              >
                                <div className="accordion-body content-body">
                                  <p className="content-body-para">
                                    Justright JR has invested in renewable energy sources, implemented recycling programs, and partnered with environmental organizations to offset our carbon emissions.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item question-content-items">
                            <div className="question-content-right">
                              <h2 className="accordion-header" id="headingThree">
                                <button
                                  className="accordion-button collapsed question-buttons-item"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseThree"
                                  aria-expanded="false"
                                  aria-controls="collapseThree"
                                >
                                  Can Justright JR products be recycled?
                                </button>
                              </h2>
                              <div
                                id="collapseThree"
                                className="accordion-collapse collapse collapse-button-content"
                                aria-labelledby="headingThree"
                                data-bs-parent="#sustainable-question"
                              >
                                <div className="accordion-body content-body">
                                  <p className="content-body-para">
                                    Yes, all Justright JR products are designed to be recyclable. Please follow local recycling guidelines for proper disposal.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item question-content-items">
                            <div className="question-content-right">
                              <h2 className="accordion-header" id="headingFour">
                                <button
                                  className="accordion-button collapsed question-buttons-item"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseFour"
                                  aria-expanded="false"
                                  aria-controls="collapseFour"
                                >
                                  How can I learn more about Justright JR's sustainability efforts?
                                </button>
                              </h2>
                              <div
                                id="collapseFour"
                                className="accordion-collapse collapse collapse-button-content"
                                aria-labelledby="headingFour"
                                data-bs-parent="#sustainable-question"
                              >
                                <div className="accordion-body content-body">
                                  <p className="content-body-para">
                                    Visit our Sustainability Initiatives page on our website to learn more about our commitment to the environment and our ongoing projects.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="tab-pane fade rightquestion-product"
                        id="v-pills-ordering"
                        role="tabpanel"
                        aria-labelledby="v-pills-ordering-tab"
                      >
                        <div
                          className="accordion question-items-accordion"
                          id="ordering-question"
                        >
                          <div className="accordion-item question-content-items">
                            <div className="question-content-right">
                              <h2 className="accordion-header" id="headingOne">
                                <button
                                  className="accordion-button question-buttons-item"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseOne"
                                  aria-expanded="true"
                                  aria-controls="collapseOne"
                                >
                                  What is the estimated delivery time for Justright JR products?
                                </button>
                              </h2>
                              <div
                                id="collapseOne"
                                className="accordion-collapse collapse show collapse-button-content"
                                aria-labelledby="headingOne"
                                data-bs-parent="#ordering-question"
                              >
                                <div className="accordion-body content-body">
                                  <p className="content-body-para">
                                    Delivery times vary based on location but typically range from 1-2 business days for domestic orders and 3-4 business days for international orders.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item question-content-items">
                            <div className="question-content-right">
                              <h2 className="accordion-header" id="headingTwo">
                                <button
                                  className="accordion-button collapsed question-buttons-item"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="collapseTwo"
                                >
                                  How can I track my Justright JR order?
                                </button>
                              </h2>
                              <div
                                id="collapseTwo"
                                className="accordion-collapse collapse collapse-button-content"
                                aria-labelledby="headingTwo"
                                data-bs-parent="#ordering-question"
                              >
                                <div className="accordion-body content-body">
                                  <p className="content-body-para">
                                    Once your order has shipped, you will receive a tracking number via email. You can use this number to track your package on our website or the carrier's site.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item question-content-items">
                            <div className="question-content-right">
                              <h2 className="accordion-header" id="headingThree">
                                <button
                                  className="accordion-button collapsed question-buttons-item"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseThree"
                                  aria-expanded="false"
                                  aria-controls="collapseThree"
                                >
                                  What are the shipping costs for Justright JR products?
                                </button>
                              </h2>
                              <div
                                id="collapseThree"
                                className="accordion-collapse collapse collapse-button-content"
                                aria-labelledby="headingThree"
                                data-bs-parent="#ordering-question"
                              >
                                <div className="accordion-body content-body">
                                  <p className="content-body-para">
                                    Shipping costs depend on the order size and destination. You can view the shipping cost at checkout before completing your purchase.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item question-content-items">
                            <div className="question-content-right">
                              <h2 className="accordion-header" id="headingFour">
                                <button
                                  className="accordion-button collapsed question-buttons-item"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseFour"
                                  aria-expanded="false"
                                  aria-controls="collapseFour"
                                >
                                  Can I change or cancel my order after it has been placed?
                                </button>
                              </h2>
                              <div
                                id="collapseFour"
                                className="accordion-collapse collapse collapse-button-content"
                                aria-labelledby="headingFour"
                                data-bs-parent="#ordering-question"
                              >
                                <div className="accordion-body content-body">
                                  <p className="content-body-para">
                                    Yes, you can change or cancel your order within 24 hours of placing it. Please contact our customer service team for assistance.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="tab-pane fade rightquestion-product"
                        id="v-pills-warrenty"
                        role="tabpanel"
                        aria-labelledby="v-pills-warrenty-tab"
                      >
                        <div
                          className="accordion question-items-accordion"
                          id="warrenty-question"
                        >
                          <div className="accordion-item question-content-items">
                            <div className="question-content-right">
                              <h2 className="accordion-header" id="headingOne">
                                <button
                                  className="accordion-button question-buttons-item"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseOne"
                                  aria-expanded="true"
                                  aria-controls="collapseOne"
                                >
                                  What is Justright JR's return policy?
                                </button>
                              </h2>
                              <div
                                id="collapseOne"
                                className="accordion-collapse collapse show collapse-button-content"
                                aria-labelledby="headingOne"
                                data-bs-parent="#warrenty-question"
                              >
                                <div className="accordion-body content-body">
                                  <p className="content-body-para">
                                    We offer a 30-day return policy for all unused and unopened products. Please visit our Returns page for more information.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item question-content-items">
                            <div className="question-content-right">
                              <h2 className="accordion-header" id="headingTwo">
                                <button
                                  className="accordion-button collapsed question-buttons-item"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="collapseTwo"
                                >
                                  How do I initiate a return for a Justright JR product?
                                </button>
                              </h2>
                              <div
                                id="collapseTwo"
                                className="accordion-collapse collapse collapse-button-content"
                                aria-labelledby="headingTwo"
                                data-bs-parent="#warrenty-question"
                              >
                                <div className="accordion-body content-body">
                                  <p className="content-body-para">
                                    To initiate a return, contact our customer service team with your order details, and they will guide you through the return process.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item question-content-items">
                            <div className="question-content-right">
                              <h2 className="accordion-header" id="headingThree">
                                <button
                                  className="accordion-button collapsed question-buttons-item"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseThree"
                                  aria-expanded="false"
                                  aria-controls="collapseThree"
                                >
                                  What is covered under Justright JR's product warranty?
                                </button>
                              </h2>
                              <div
                                id="collapseThree"
                                className="accordion-collapse collapse collapse-button-content"
                                aria-labelledby="headingThree"
                                data-bs-parent="#warrenty-question"
                              >
                                <div className="accordion-body content-body">
                                  <p className="content-body-para">
                                    Our products come with a one-year warranty covering manufacturing defects. Damage due to misuse or normal wear and tear is not covered.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item question-content-items">
                            <div className="question-content-right">
                              <h2 className="accordion-header" id="headingFour">
                                <button
                                  className="accordion-button collapsed question-buttons-item"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseFour"
                                  aria-expanded="false"
                                  aria-controls="collapseFour"
                                >
                                  How long does it take to process a return or exchange?
                                </button>
                              </h2>
                              <div
                                id="collapseFour"
                                className="accordion-collapse collapse collapse-button-content"
                                aria-labelledby="headingFour"
                                data-bs-parent="#warrenty-question"
                              >
                                <div className="accordion-body content-body">
                                  <p className="content-body-para">
                                    Returns and exchanges are typically processed within 4-5 business days after we receive the returned item.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="tab-pane fade rightquestion-product"
                        id="v-pills-technical"
                        role="tabpanel"
                        aria-labelledby="v-pills-technical-tab"
                      >

                        <div
                          className="accordion question-items-accordion"
                          id="technical-question"
                        >
                          <div className="accordion-item question-content-items">
                            <div className="question-content-right">
                              <h2 className="accordion-header" id="headingOne">
                                <button
                                  className="accordion-button question-buttons-item"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseOne"
                                  aria-expanded="true"
                                  aria-controls="collapseOne"
                                >
                                  How do I install Justright JR aluminum panels?
                                </button>
                              </h2>
                              <div
                                id="collapseOne"
                                className="accordion-collapse collapse show collapse-button-content"
                                aria-labelledby="headingOne"
                                data-bs-parent="#technical-question"
                              >
                                <div className="accordion-body content-body">
                                  <p className="content-body-para">
                                    Each panel comes with an installation guide. You can also find detailed installation videos on our website under the Support section.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item question-content-items">
                            <div className="question-content-right">
                              <h2 className="accordion-header" id="headingTwo">
                                <button
                                  className="accordion-button collapsed question-buttons-item"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="collapseTwo"
                                >
                                  What should I do if my LED lights are not functioning properly?
                                </button>
                              </h2>
                              <div
                                id="collapseTwo"
                                className="accordion-collapse collapse collapse-button-content"
                                aria-labelledby="headingTwo"
                                data-bs-parent="#technical-question"
                              >
                                <div className="accordion-body content-body">
                                  <p className="content-body-para">
                                    Check the power connection and ensure that the wiring is correct. If the issue persists, refer to the troubleshooting guide included with the product or contact our technical support team.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item question-content-items">
                            <div className="question-content-right">
                              <h2 className="accordion-header" id="headingThree">
                                <button
                                  className="accordion-button collapsed question-buttons-item"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseThree"
                                  aria-expanded="false"
                                  aria-controls="collapseThree"
                                >
                                  Can I get assistance with designing and setting up my signage?
                                </button>
                              </h2>
                              <div
                                id="collapseThree"
                                className="accordion-collapse collapse collapse-button-content"
                                aria-labelledby="headingThree"
                                data-bs-parent="#technical-question"
                              >
                                <div className="accordion-body content-body">
                                  <p className="content-body-para">
                                    Yes, our technical support team can assist you with design recommendations and setup instructions for your signage. Contact us with your project details for personalized support.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item question-content-items">
                            <div className="question-content-right">
                              <h2 className="accordion-header" id="headingFour">
                                <button
                                  className="accordion-button collapsed question-buttons-item"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseFour"
                                  aria-expanded="false"
                                  aria-controls="collapseFour"
                                >Where can I find the product specifications for proper installation and maintenance?
                                </button>
                              </h2>
                              <div
                                id="collapseFour"
                                className="accordion-collapse collapse collapse-button-content"
                                aria-labelledby="headingFour"
                                data-bs-parent="#technical-question"
                              >
                                <div className="accordion-body content-body">
                                  <p className="content-body-para">
                                    Product specifications and maintenance guides are available on our website under each product listing. For additional support, you can reach out to our technical support team.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="question-section">
          <div className="container">
            <div className="have-questions">
              <div className="still-images">
                <div className="imagestiil">
                  <img src="/images/landing/faq-1.webp" className="getimage" alt="/images/faq/faq-1.webp" />
                  <img src="/images/landing/faq-2.webp" className="getimage" alt="/images/faq/faq-2.webp" />
                  <img src="/images/landing/faq-3.webp" className="getfirstimag" alt="/images/faq/faq-3.webp" />
                </div>
              </div>

              <h1 className="still-questions-head">Still have questions?</h1>
              <p className="question-para">
                Can’t find the answer you’re looking for? Please chat to our
                friendly team.
              </p>
              <div className="question-buttons">
                <NavLink to="/contact-us" className="question-email-button">
                  Get in touch
                  <i className="fas fa-external-link-alt btn-get"></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <Newsletter />
      </div>
    </>
  )
}

export default Faqpage