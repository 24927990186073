import React from 'react'
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <>
    <div className='footer-content'>
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="footer-left">
             <div className="footer-image"> <img className="image-data" src="./images/navbar/logo-1.svg" alt="no-data" /></div>
             <p className="footer-para">Cultivating a future where sustainability and technology go hand in hand.</p>
            </div>

          </div>
          <div className="col-md-2 col-6">
            <div className="footer-middle">
              <ul className="footer-menus">
                <li className="menus-list">
                  <NavLink className="inside-menus" to="/about-us">About Us
                  <i className="fa fa-arrow-right icon-lists" aria-hidden="true" ></i>
                  </NavLink>
                </li>
                <li className="menus-list">
                  <NavLink className="inside-menus" to="/our-products">Our Products
                  <i className="fa fa-arrow-right icon-lists" aria-hidden="true" ></i>
                  </NavLink>
                </li>
                <li className="menus-list">
                  <NavLink className="inside-menus" to="/sustainability-Initiatives">Initiatives
                  <i className="fa fa-arrow-right icon-lists" aria-hidden="true" ></i>
                  </NavLink>
                </li>
                <li className="menus-list">
                  <NavLink className="inside-menus" to="/latest-innovations">Innovations
                  <i className="fa fa-arrow-right icon-lists" aria-hidden="true" ></i>
                  </NavLink>
                </li>
              </ul>

            </div>

          </div>
          <div className="col-md-2 col-6">
          <div className="footer-middle">
              <ul className="footer-menus">
                <li className="menus-list">
                  <NavLink  className="inside-menus" to="/productlaunch">Launches
                  <i className="fa fa-arrow-right icon-lists" aria-hidden="true" ></i>
                  </NavLink>
                </li>
                <li className="menus-list">
                  <NavLink className="inside-menus" to="">Engagements
                  <i className="fa fa-arrow-right icon-lists" aria-hidden="true" ></i>
                  </NavLink>
                </li>
                <li className="menus-list">
                  <NavLink className="inside-menus" to="">Programs
                  <i className="fa fa-arrow-right icon-lists" aria-hidden="true" ></i>
                  </NavLink>
                </li>
                <li className="menus-list">
                  <NavLink className="inside-menus" to="/contact-us">Contact Us
                  <i className="fa fa-arrow-right icon-lists" aria-hidden="true" ></i></NavLink>
                </li>
                <li className="menus-list">
                  <NavLink className="inside-menus" to="/faq">FAQs
                  <i className="fa fa-arrow-right icon-lists" aria-hidden="true" ></i></NavLink>
                </li>
              </ul>

            </div>
          </div>

        </div>
        <div className="all-rights">
          <ul className="rights-ul">
            <li className="rights-li ml-auto">
              <p className="right-para">© 2024 JustRight. All rights reserved.</p>
            </li>
            <li className="rights-li rights-li1">
              <NavLink className="right-para right-para1">Term</NavLink>
            </li>
            <li className="rights-li ">
              <NavLink className="right-para right-para1">Privacy</NavLink>
            </li>
            <li className="rights-li ">
              <NavLink className="right-para right-para1">Cookies</NavLink>
            </li>
          

          </ul>
        </div>

      </div>
    </div>
    </>
  )
}

export default Footer