import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <CookieConsent
      location="bottom"
      buttonText="I ACCEPT"
      declineButtonText="I DECLINE"
      enableDeclineButton
      cookieName="Justright"
      margin="0px"
      
      style={{
        background: "#FFC322",
        borderRadius: "4px",
        padding: "5px 10px", // Using vw for responsive padding
        outline: "none",
        color: "#000000",
        margin: "0px",
        fontSize: "14px", // Using vw for responsive font size
        fontWeight: "500",
        
        border: "1px solid transparent",
        backgroundImage: "#FFC322",
        borderImage: "#FFC322",
        borderImageSlice: "1",
      }}
      buttonStyle={{
        padding: "5px 10px", // Using vw for responsive padding
        borderRadius: "4px",
        outline: "none",
        margin: "0px 5px",
        color: "white",
        fontSize: "14px", // Using vw for responsive font size
        fontWeight: "500",
        border: "1px solid transparent",
        backgroundColor: "#000000",
        borderImageSlice: "1",
      }}
      declineButtonStyle={{
        padding: "5px 10px", // Using vw for responsive padding
        borderRadius: "4px",
        backgroundColor: "#d5d7da",
        outline: "none",
        border: "1px solid transparent", // Add border for visibility
        color: "#7c828d",
        fontSize: "14px", // Using vw for responsive font size
        fontWeight: "500",
        margin: "0px 5px",
      }}
      
      expires={150}
    >
      This website uses cookies to enhance the user experience.{" "}
    </CookieConsent>
  <App />
</BrowserRouter>
);
reportWebVitals();
