import React from "react";

const Loader = () => {
  return (
    <>
   <div className="loaderSection">
   <div id="logo-animation" className="logo-container">
        <img src="/images/navbar/fav.svg" alt="Logo" className="logo" />
      </div>
   </div>
    </>
  );
};

export default Loader;
