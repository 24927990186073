import React, { useEffect, useState } from "react";
import Newsletter from "../Newsletter/Newsletter";
import ApiNames from '../../Constants/ApiNames';
import Axios from '../../Utils/AxiosConfig';
import toastr from 'toastr';
import 'toastr/build/toastr.css';
import { Helmet } from "react-helmet";
import {
  showSuccessToast,
  showErrorToast,
} from '../../Utils/toastrService';

const Contactus = () => {
  const [countryCodes, setCountryCodes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [agreeToPolicy, setAgreeToPolicy] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
 
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    try {
      const url = ApiNames.justright_Countries;
      const response = await Axios.post(url, { name: "" });
      setCountryCodes(response.data);
    } catch (error) {
      showErrorToast('Failed to fetch countries');
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectCountryCode = (countryCode) => {
    setSelectedCountryCode(countryCode);
  };

  const filteredCountryCodes = countryCodes.filter(country =>
    country.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!firstname || !lastname || !email || !phone || !message) {
      showErrorToast('Please fill out all fields.');
      return;
    }

    if (!selectedCountryCode) {
      showErrorToast('Please select a country.');
      return;
    }

    if (!agreeToPolicy) {
      showErrorToast('You must agree to the privacy policy.');
      return;
    }

    setIsLoading(true);

    try {
      const url = ApiNames.justright_Contactus;
      const response = await Axios.post(url, {
        firstname,
        lastname,
        email,
        mobileNumber: phone,
        countryCode: selectedCountryCode,
        description: message,
      });
      showSuccessToast(response.data.message);
      setFirstname("");
      setLastname("");
      setEmail("");
      setPhone("");
      setMessage("");
      setAgreeToPolicy(false);
      setSelectedCountryCode(""); // Reset country code
    } catch (error) {
      showErrorToast(error.response?.data?.message || 'Failed to submit form.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>
        Contact Us
        </title>
      </Helmet>
      <div className="contact-us-page">
        <div className="contact-us">
          <div className="container">
            <div className="contact-header">
              <h1 className="contact-head">We’d love to hear from you</h1>
              <p className="contact-teampara">
                Our friendly support team is always here to chat and help you around.
              </p>
            </div>
            <div className="contact-information">
              <div className="row">
                <div className="col-md-4">
                  <div className="contact-email">
                    <div className="email-icons">
                      <i className="far fa-envelope mail-icon"></i>
                    </div>
                    <div className="email-head-para">
                      <p className="email-head">Email</p>
                      <p className="email-para">
                        Our friendly team is here to help.
                      </p>
                    </div>
                    <a href="mailto:mail@justright.com" className="emailcontact">
                      info@justrightinc.com
                    </a>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="contact-email">
                    <div className="email-icons">
                      <i className="fas fa-map-marker-alt mail-icon"></i>
                    </div>
                    <div className="email-head-para">
                      <p className="email-head">Office</p>
                      <p className="email-para">
                        Come say hello at our office HQ.
                      </p>
                    </div>
                    <a href="#" className="emailcontact">
                    See Visual Goods - Wholesalers L.L.C. - Al Khabaisi Street, Deira, Dubai, U.A.E. - Retail Trading Division
                    </a>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="contact-email">
                    <div className="email-icons">
                    <i class="fa-solid fa-phone mail-icon"></i>
                    </div>
                    <div className="email-head-para">
                      <p className="email-head">Phone</p>
                      <p className="email-para">Mon-Fri from 8am to 5pm.</p>
                    </div>
                    <a href="tel:+97142655600" className="emailcontact">
                    +97142655600
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="get-in-touch">
          <div className="container">
            <div className="row h-100">
              <div className="col-md-6">
                <form className="contact-form" onSubmit={handleSubmit}>
                  <div className="contact-header">
                    <h1 className="contact-head">Get in touch</h1>
                    <p className="contact-teampara">
                      You can send in your inquiries, suggestions and feedback to us.
                    </p>
                  </div>
                  <div className="get-in-touch-form">
                    <div className="form-names">
                      <div className="row">
                        <div className="col-md-6">
                          <label className="label-texts">First Name</label>
                          <input
                            type="text"
                            className="firstname"
                            placeholder="First Name"
                            value={firstname}
                            onChange={(e) => setFirstname(e.target.value)}
                            required
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="label-texts">Last Name</label>
                          <input
                            type="text"
                            className="firstname"
                            placeholder="Last Name"
                            value={lastname}
                            onChange={(e) => setLastname(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-email">
                      <label className="label-texts">Email</label>
                      <input
                        type="email"
                        placeholder="Email"
                        className="form-email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <label className="label-texts">Phone Number</label>
                    <div className="phone-input-top">
                      <div className="dropdown phone-dropdown">
                        <button
                          className="dropdown-toggle dropdown-flag-button"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {selectedCountryCode || "Code"}
                        </button>
                        <ul
                          className="dropdown-menu countrycode-top"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li className="searchbar-list">
                            <div className="dropdowninsideimage">
                              <i className="fas fa-search registration-img"></i>
                              <input
                                type="text"
                                className="form-control search-inpuut"
                                placeholder="Search Country"
                                value={searchTerm}
                                onChange={handleSearch}
                              />
                            </div>
                          </li>
                          <div className="search-box-top">
                            {filteredCountryCodes.map((country) => (
                              <li
                                key={country.code}
                                className="dropdown-flag-list"
                                onClick={() => handleSelectCountryCode(country.code)}
                              >
                                <a className="dropdown-item" href="#">
                                  <span className="flag-span">{country.name}</span>
                                </a>
                              </li>
                            ))}
                          </div>
                        </ul>
                      </div>
                      <input
                        type="text"
                        className="form-control-input"
                        placeholder="+1 (555) 000-0000"
                        value={phone}
                        onChange={(e) => {
                          const input = e.target.value;
                          // Remove all non-numeric characters from input value
                          const phoneNumber = input.replace(/\D/g, '');
                          // Update state with filtered value
                          setPhone(phoneNumber);
                        }}
                        required
                      />
                    </div>
                    <label className="label-texts">Message</label>
                    <textarea
                      id="message"
                      placeholder="Message"
                      className="textmessage"
                      rows="4"
                      cols="50"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                    ></textarea>
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        id="checkbox1"
                        className="checkboxmail"
                        name="checkbox1"
                        checked={agreeToPolicy}
                        onChange={() => setAgreeToPolicy(!agreeToPolicy)}
                      />
                      <label>
                        <p className="checkboxpara">
                          I agree to JustRight
                          <a href="" className="checkboxlink">
                            privacy policy
                          </a>
                        </p>
                      </label>
                    </div>
                    <button type="submit" className="messagebutton" disabled={isLoading}>
                      {isLoading ? "Sending..." : "Send Message"}
                      <i className="fa-solid fa-arrow-right arow-right"></i>
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-md-6">
                <div className="image-top ">
                  <img
                    src="/images/contactus/contact-image.webp"
                    alt="contactus"
                    className="contact-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Newsletter />
      </div>
    </>
  );
};

export default Contactus;
