import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from "react-helmet";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { Pagination, Navigation } from "swiper/modules";


const Aboutus = () => {
  // const videoPath = process.env.PUBLIC_URL + '/video/video.mp4';
  const videoRef = useRef(null);
  // const [isPlaying, setIsPlaying] = useState(false);
  // const [isMuted, setIsMuted] = useState(true);
  const [ClickedSlide, SetClickedSlide] = useState(0);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1800);


  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  // const togglePlayPause = () => {
  //   if (videoRef.current.paused) {
  //     videoRef.current.play();
  //     setIsPlaying(true);
  //   } else {
  //     videoRef.current.pause();
  //     setIsPlaying(false);
  //   }
  // };

  // const toggleMute = () => {
  //   videoRef.current.muted = !videoRef.current.muted;
  //   setIsMuted(videoRef.current.muted);
  // };


  const handleClick = (index) => {
    SetClickedSlide(index);
  };

  const slides = [
    '/images/aboutus/slide1.png',
    '/images/aboutus/slide2.png',
    '/images/aboutus/slide3.png',
    '/images/aboutus/slide4.png',
    '/images/aboutus/slide1.png',
    '/images/aboutus/slide2.png',
    '/images/aboutus/slide3.png',
    '/images/aboutus/slide4.png',
    '/images/aboutus/slide1.png',
    '/images/aboutus/slide2.png',
    '/images/aboutus/slide3.png',
    '/images/aboutus/slide4.png',
  ];
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1800);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>
          About Us
        </title>
      </Helmet>
      <div className='aboutus-page'>
        <div className='aboutus-hero'>
          <div className='container'>
            <div className='aboutus-content'>
              <h3 className='aboutus-title'>About Us</h3>
              <p className='aboutus-para'>Crafting products; we're cultivating a future where sustainability and technology go hand in hand.</p>

            </div>
            {/* <div className='row justify-content-center'>
              <div className='col-md-12'>
                <div className='aboutus-hero'>
                  <div className='aboutus-hero-inside'>
                    <video ref={videoRef} onClick={togglePlayPause}>
                      <source src={videoPath} type='video/mp4' />
                      Your browser does not support the video tag.
                    </video>

                    <div className='custom-controls'>
                      <button className='play-pause-btn' onClick={togglePlayPause}>
                        {isPlaying ? (
                          <img className='play-pause' src="/video/pause.svg" alt='Pause' />
                        ) : (
                          <img className='play-pause' src="/video/play.svg" alt='Play' />
                        )}
                      </button>

                     <button className='mute-btn' onClick={toggleMute}>
                        {isMuted ? 'Unmute' : 'Mute'}
                      </button> 
                    </div>
                    <img className='aboutus-images' src='/images/aboutus/aboutus-image.webp' alt='aboutus' />
                  </div>
                </div>
              </div>
            </div> */}


          </div>

          <div className='aboutus-hero'>
            <div className='container'>
              <div className='aboutus-hero-inside'>
                <img className='aboutus-images' src='/images/aboutus/aboutus-image.jpeg' alt='aboutus' />
              </div>
            </div>
          </div>
        </div>
        <div className='ourvision-section'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='ourvision-left'>
                  <h3 className='ourvision-title'>Our Vision</h3>
                  <p className='ourvision-subtitle'>Intertwining sustainability with innovation.</p>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='ourvision-right'>
                  <p className='ourvision-para'>In the tapestry of a greener future, JustRight crafts a world where every product we manufacture intertwines sustainability with innovation. We're committed to redefining quality and affordability, ensuring each solution is as kind to the earth as it is to our customers' aspirations.</p>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div className='repurposing-section'>
          <div className='container'>
            <Swiper
              direction={"horizontal"}
              centeredSlides={false}
              loop={true}
              pagination={{
                clickable: true,
              }}

              autoplay={{
                delay: 6500,
                disableOnInteraction: false,
              }}
              // navigation={true}
              breakpoints={{
                340: {
                  slidesPerView: 2,
                  spaceBetween: 0,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 0,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 0,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 0,
                },
              }}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
              grabCursor={true}
              spaceBetween={50}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
              scrollbar={{ draggable: true }}
              dir="ltr"
              onSlideChange={(swiper) => SetClickedSlide(swiper.realIndex)}
              onSwiper={(swiper) => {
                swiper.slideTo(ClickedSlide, 0, false); // Ensuring the active slide is initially centered
              }}
            >
              {slides.map((slide, index) => (
                <SwiperSlide key={index} onClick={() => handleClick(index)}>
                  <div
                    className={`card card-content ${ClickedSlide === index ? 'swiper-slide-clicked' : ''}`}
                  >
                    <div className="card-body card-b">
                      <img
                        src={slide}
                        className="card-img-top service-image"
                        alt="no-data"
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

        </div>
        <div className='ourmission-section'>
          <div className='container'>
            <div className='row'>

              <div className='col-md-6'>
                <div className='ourmission-right'>
                  <p className='ourmission-para'>Our mission is to champion the transformation of recyclable materials into premium building facades, interiors, and architectural elements. JustRight dedicates itself to pioneering repurposing practices, ensuring our diverse array of eco-sustainable products supports both the environment and our clientele's vision.</p>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='ourmission-left'>
                  <h3 className='ourmission-title'>Our Mission</h3>
                  <p className='ourmission-subtitle'>Repurposing practices,  improving the ecosystem</p>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div className='whychoose-section'>
          <div className='container'>
            <div className='whychoose-content'>
              <h3 className='whychoose-title'>Why Choose JustRight?</h3>
              <p className='whychoose-para'>Lorem ipsum dolor sit amet consectetur. Mattis dui malesuada justo at suspendisse nunc.</p>
            </div>
            <div className='cards-view'>
              <div className="row">
                <div className="col-md-4">
                  <div className="card news-card">
                    <img src="/images/aboutus/icon-1.png" className="card-img-top news-top-image" alt="no-data"></img>
                    <div className="card-body news-body">
                      <h1 className="card-text news-text">Quality Assurance</h1>
                      <p className="card-text news-text1">Unmatched durability and aesthetics, thanks to our rigorous testing standards.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card news-card">
                    <img src="/images/aboutus/icon-2.png" className="card-img-top news-top-image" alt="..."></img>
                    <div className="card-body news-body">
                      <h1 className="card-text news-text">Innovative Solutions</h1>
                      <p className="card-text news-text1">Pioneering with new materials and technologies for standout market solutions.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card news-card">
                    <img src="/images/aboutus/icon-3.png" className="card-img-top news-top-image" alt="..."></img>
                    <div className="card-body news-body">
                      <h1 className="card-text news-text">Sustainable Practices</h1>
                      <p className="card-text news-text1">Committed to the planet through eco-friendly materials and energy-efficient innovations.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='cards-view'>
              <div className="row">
                <div className="col-md-4">
                  <div className="card news-card">
                    <img src="/images/aboutus/icon-4.png" className="card-img-top news-top-image" alt="..."></img>
                    <div className="card-body news-body">
                      <h1 className="card-text news-text">Customer-Centric Service</h1>
                      <p className="card-text news-text1">Dedicated support, from selection to post-sale, for a seamless experience.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card news-card">
                    <img src="/images/aboutus/icon-5.png" className="card-img-top news-top-image" alt="..."></img>
                    <div className="card-body news-body">
                      <h1 className="card-text news-text">Plastic Scrap Buyback</h1>
                      <p className="card-text news-text1">Elevating our sustainability commitment by inviting customers to join our recycling initiative, promoting a circular economy.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card news-card">
                    <img src="/images/aboutus/icon-6.png" className="card-img-top news-top-image" alt="..."></img>
                    <div className="card-body news-body">
                      <h1 className="card-text news-text">Holistic Eco-Friendliness</h1>
                      <p className="card-text news-text1">From design to delivery, our products are a testament to sustainability, combining high performance with environmental respect.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className='our-greener'>
          <div className='container'>
            <h1 className='greener-head'>Our Commitment to a Greener Future</h1>
            <p className='greener-paragraph'>Lorem ipsum dolor sit amet consectetur. Non enim habitant et amet risus nunc amet. Vestibulum metus maecenas malesuada sit faucibus.</p>
            <div className='grener-content'>
              <div className='row'>
                <div className='col-md-6 '>
                  <div className='content-greener'>

                    <ul className='grener-list'>
                      <li><img className='tick' src='/images/aboutus/tick.png'></img></li>
                      <li className='future-list'><h1 className='futur-head'>Greener Future Commitment</h1>
                        <p className='central-para'>Central to JustRight; balancing quality with eco-respect.</p>
                      </li>
                    </ul>
                    <ul className='grener-list'>
                      <li><img className='tick' src='/images/aboutus/tick.png'></img></li>
                      <li className='future-list'><h1 className='futur-head'>Recycled Product Range</h1>
                        <p className='central-para'>Proudly offering items made from 95% reusable plastic.</p>
                      </li>
                    </ul>
                    <ul className='grener-list'>
                      <li><img className='tick' src='/images/aboutus/tick.png'></img></li>
                      <li className='future-list'><h1 className='futur-head'>Material Selection</h1>
                        <p className='central-para'>Focused on sustainable, recyclable resources for product design.</p>
                      </li>
                    </ul>
                    <ul className='grener-list'>
                      <li><img className='tick' src='/images/aboutus/tick.png'></img></li>
                      <li className='future-list'><h1 className='futur-head'>Manufacturing Efficiency</h1>
                        <p className='central-para'>Aimed at reducing waste and maximizing energy efficiency.</p>
                      </li>
                    </ul>
                    <ul className='grener-list'>
                      <li><img className='tick' src='/images/aboutus/tick.png'></img></li>
                      <li className='future-list'><h1 className='futur-head'>Product Lifecycle</h1>
                        <p className='central-para'>Designed for ease of recycling, supporting the circular economy.</p>
                      </li>
                    </ul>
                    <ul className='grener-list'>
                      <li><img className='tick' src='/images/aboutus/tick.png'></img></li>
                      <li className='future-list'><h1 className='futur-head'>Recycled Quality</h1>
                        <p className='central-para'>No compromise on performance, aligning eco-friendliness with excellence.</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-md-6 '>
                  <div className='greenimage'>
                    <img className='green-img' src='/images/aboutus/image-2.webp'></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='sustain-section'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 order-md-1 order-2'>
                <div className='sustaible'>
                  <img className='sus-image' src='/images/aboutus/image-1.webp'></img>
                </div>
              </div>
              <div className='col-md-6 order-md-1 order-1'>
                <div className='sustainble-content'>
                  <div className='sustainable-data'>
                    <h1 className='ewaving-head'>Sustainability Commitment</h1>
                    <p className='weaving-para'>JustRight is steadfast in weaving sustainability into every fiber of our operations. From procuring raw, recyclable materials to delivering end products like our PC Multiwall Sheets, every step reinforces our pledge to the planet. With a proactive approach to repurposing, we ensure each product in our portfolio contributes to a greener future.</p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='teams-section'>
          <div className={isLargeScreen ? 'container' : ''}>
            <h1 className='team-head'>Team</h1>
            <p className='team-content'>The strength of JustRight lies in the passionate professionals behind our products. Our team, diverse in expertise but united in vision, drives the innovation and dedication seen in every eco-conscious solution we offer, from PVC Boards to advanced Tapes & Industrial Glues.</p>
            {/* <button className='repurpose-btn'>Team Info<i className="fas fa-arrow-right cstmarw"></i></button> */}
          </div>
          <div className="container">
            <div className='cards-view1'>
              <div className="row justify-content-center">
                <div className="col-md-2">
                  <div className="card news-card1">
                    <img src="/images/aboutus/female.svg" className="card-img-top news-top-image1" alt="..."></img>
                    <div className="card-body news-body1">
                      <h1 className="card-text news-text1">Kalpana Jain</h1>
                      <p className="card-text news-text11">Founder</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="card news-card1">
                    <img src="/images/aboutus/male.svg" className="card-img-top news-top-image1" alt="..."></img>
                    <div className="card-body news-body1">
                      <h1 className="card-text news-text1">Jay Jain</h1>
                      <p className="card-text news-text11">Director</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="card news-card1">
                    <img src="/images/aboutus/male.svg" className="card-img-top news-top-image1" alt="..."></img>
                    <div className="card-body news-body1">
                      <h1 className="card-text news-text1">Usman Malik</h1>
                      <p className="card-text news-text11">Chief Financial Officer</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="card news-card1">
                    <img src="/images/aboutus/male.svg" className="card-img-top news-top-image1" alt="..."></img>
                    <div className="card-body news-body1">
                      <h1 className="card-text news-text1">Muhammad Nadeem</h1>
                      <p className="card-text news-text11">Senior Accountant</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="card news-card1">
                    <img src="/images/aboutus/male.svg" className="card-img-top news-top-image1" alt="..."></img>
                    <div className="card-body news-body1">
                      <h1 className="card-text news-text1">Geevarughese George</h1>
                      <p className="card-text news-text11">Manager</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className='cards-view1 cards-view11'>
              <div className="row justify-content-center">
                <div className="col-md-2">
                  <div className="card news-card1">
                    <img src="/images/aboutus/male.svg" className="card-img-top news-top-image1" alt="..."></img>
                    <div className="card-body news-body1">
                      <h1 className="card-text news-text1">Biju Varghese</h1>
                      <p className="card-text news-text11">Retail Sales Manager</p>
                    </div>
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="card news-card1">
                    <img src="/images/aboutus/female.svg" className="card-img-top news-top-image1" alt="..."></img>
                    <div className="card-body news-body1">
                      <h1 className="card-text news-text1">Marylyn Legaspi</h1>
                      <p className="card-text news-text11">Senior Sales Head</p>
                    </div>
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="card news-card1">
                    <img src="/images/aboutus/female.svg" className="card-img-top news-top-image1" alt="..."></img>
                    <div className="card-body news-body1">
                      <h1 className="card-text news-text1">Russeth Barque</h1>
                      <p className="card-text news-text11">Sales Executive</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="card news-card1">
                    <img src="/images/aboutus/male.svg" className="card-img-top news-top-image1" alt="..."></img>
                    <div className="card-body news-body1">
                      <h1 className="card-text news-text1">Millet Polintan</h1>
                      <p className="card-text news-text11">Logistist Executive</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="card news-card1">
                    <img src="/images/aboutus/female.svg" className="card-img-top news-top-image1" alt="..."></img>
                    <div className="card-body news-body1">
                      <h1 className="card-text news-text1">Maria CeCilia Santiago</h1>
                      <p className="card-text news-text11">Public Relations Officer</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default Aboutus