import React, { useEffect } from 'react'
import Newsletter from '../Newsletter/Newsletter'
import { Helmet } from "react-helmet";
import { NavLink } from 'react-router-dom';

const ProductLaunch = () => {

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>
                    Products launch
                </title>
            </Helmet>
            <div className='products-content'>
                <h1 className='product-head'>Sustainability Breakthroughs</h1>
                <h1 className='launch-head'>Innovation and collaboration for transformative solutions</h1>
                <p className='product-para'>JustRight continues to make significant strides in sustainability practices across all the industries where its products are in use.</p>
                {/* ======================= Green Initiative: Circular Economy and Green Plastic ============= */}
                <div className='newproduct-data'>
                    <div className="container">
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='newproduct-content'>
                                    <div className='newproduct-content-new'>
                                        <h1 className='Green-head'>Green Initiative: Circular Economy and Green Plastic</h1>
                                        <h1 className='Addressing-head'>Addressing the challenges of the use of plastic in the environment</h1>
                                        <p className='technologies-para'>JustRight uses innovative technologies to develop and produce eco-friendly plastics from post-consumer and post-industrial waste through:</p>
                                        <ul className='launch-list'>
                                            <li className='circle'><i class="fa-solid fa-circle"></i></li>
                                            <li className='listing'>Chemical recycling</li>
                                        </ul>
                                        <ul className='launch-list'>
                                            <li className='circle'><i class="fa-solid fa-circle"></i></li>
                                            <li className='listing'>Mechanical recycling</li>
                                        </ul>
                                        <ul className='launch-list'>
                                            <li className='circle'><i class="fa-solid fa-circle"></i></li>
                                            <li className='listing'>Upcycling</li>
                                        </ul>
                                        <NavLink to="/sustainability-Initiatives" className='innovat-btn'>Learn more<span><i class="fa-solid fa-arrow-right innovarrow-i"></i></span></NavLink>

                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='innovat-image'>
                                    <div className='innovat-image-new'>
                                        <img className='innovat-img' src='/images/productlaunch/image-1.webp' alt='no-data'/>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='newproduct-data1'>
                    <div className="container">
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='innovat-image'>
                                    <div className='innovat-image-new'>
                                        <img className='innovat-img' src='/images/productlaunch/image-2.webp'alt='no-data'/>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='newproduct-content'>
                                    <div className='newproduct-content-new'>
                                        <h1 className='Green-head'>Achieving sustainability with Röhm</h1>
                                        <h1 className='Addressing-head'>Fast implementation of circular economy and increasing value to Röhm GmbH</h1>
                                        <p className='technologies-para'>By leveraging Just Right’s recycling solutions, Röhm developed efficient processes and operating procedures for post-industrial waste collection.</p>
                                        <p className='technologies-para'>Röhm’s business enjoyed a significant boost from the resulting increase in the production of “Green Sheets” from recycled PMMA.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='newproduct-data1 newproduct-data11'>
                    <div className="container">
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='newproduct-content'>
                                    <div className='newproduct-content-new'>
                                        <h1 className='Green-head'>Achieving sustainability with Röhm</h1>
                                        <h1 className='Addressing-head'>Fast implementation of circular economy and increasing value to Röhm GmbH</h1>
                                        <p className='technologies-para'>By leveraging Just Right’s recycling solutions, Röhm developed efficient processes and operating procedures for post-industrial waste collection.</p>
                                        <p className='technologies-para'>Röhm’s business enjoyed a significant boost from the resulting increase in the production of “Green Sheets” from recycled PMMA.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='innovat-image'>
                                    <div className='innovat-image-new'>
                                        <img className='innovat-img' src='/images/productlaunch/image-2.webp' alt='no-data'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ============================== Industry Trends ============ */}
                <div className='industry-content'>
                    <h1 className='industry-head'>Industry Trends</h1>
                    <h1 className='next-gen-head'>Next-Gen Sustainability Innovations</h1>
                    <p className='trans-para'>Transforming the manufacturing industry one material at a time</p>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='grren-supply'>
                                    <div className='grren-supply-new'>
                                        <h1 className='supply-content'>Green Supply Chains</h1>
                                        <h1 className='reducing-head'>Reducing carbon imprint</h1>
                                        <p className='source-para'>Responsible sourcing of materials, cutting down on transport emissions, and close integration with all aspects of the supply chain to foster sustainability</p>
                                        <h1 className='supply-content'>Water Conservation</h1>
                                        <h1 className='reducing-head'>Growing businesses by reducing water waste and making a difference for future generations</h1>
                                        <p className='source-para'>Using water-saving technologies in all manufacturing activities and chemical treatment of wastewater is essential to reduce negative environmental impact</p>

                                        <h1 className='supply-content'>Lean Manufacturing</h1>
                                        <h1 className='reducing-head'>Streamline workflows and optimize business operations for sustainable growth</h1>
                                        <p className='source-para'>Minimizing the production of environmental and chemical waste in manufacturing can reduce energy consumption and harmful emissions</p>

                                        <h1 className='supply-content'>Regenerative Manufacturing</h1>
                                        <h1 className='reducing-head'>Innovating manufacturing design processes that foster environmental restoration</h1>
                                        <p className='source-para'>The adoption of the circular economy principle can help maximize the efficiency of raw resources for a healthier planet.</p>
                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6'>
                                <div className='product-right-data'>
                                    <div className='product-right'>
                                        <img className='right-img' src='/images/productlaunch/image-3.webp' alt='no-data'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <Newsletter />

        </>
    )
}

export default ProductLaunch