import React from 'react'
import { useEffect, useState } from "react";
import { NavLink, useParams } from 'react-router-dom';
import ApiNames from '../../Constants/ApiNames';
import Axios from '../../Utils/AxiosConfig';
import Newsletter from '../Newsletter/Newsletter';
import { Helmet } from "react-helmet";

const AllBlogs = () => {
    const [blogsData, setBlogsData] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    });

    useEffect(() => {
        const getBlogs = async () => {
            try {
                let api = ApiNames.getBlogs
                const response = await Axios.get(
                    `${api}`
                );

                setBlogsData(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        getBlogs();
    }, []);


    return (
        <>
          <Helmet>
        <title>
          All Blog
        </title>
      </Helmet>

            <div className="allblogs-data">
                <div className="container">
                    <div className="allblogs-header">
                        <h5 className="allblogs-para">Blog</h5>
                        <h1 className="allblogs-head"> Insight and Updates </h1>
                        <p className="allblogs-teampara">
                            Our blog offers a wealth of insightful content and regular updates,
                            keeping you informed about industry trends, expert perspectives, and
                            valuable tips to enhance your knowledge and stay ahead in your field.
                        </p>
                      
                    </div>
                </div>
                <div className="desort-board-background">
                    <div className="container">
                        <div className="desort-board">
                            <div className="row">
                                {blogsData.map((products, index) => (
                                    <div key={index} className="col-md-3">
                                        <NavLink className="card invention-items" to={`/bloginfo/${products._id}`}>
                                            <div className="inventionitem-image">
                                                <img
                                                    src={products?.image}
                                                    className="card-img-top image-data"
                                                    alt="Card"
                                                />
                                            </div>
                                            <div className="card-body invention-body">
                                                <ul className="invention-card-ul">
                                                    <li className="invention-card-li">
                                                        <p className="invention-card-title">{products?.title.slice(0, 60)}</p>
                                                    </li>
                                                    <li className="invention-card-li">
                                                        <i className="fas fa-arrow-right right-icon"></i>
                                                    </li>
                                                </ul>

                                                <p className="invention-card-para">
                                                    {products?.description.slice(0, 200)}
                                                </p>

                                            </div>
                                        </NavLink>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <Newsletter />
            </div>
        </>
    )
}

export default AllBlogs