const ApiNames = {

    Subscribe_Newsletter:"users/subscribeNow",
    justright_Contactus:"users/contactUs",
    justright_Countries:"users/searchCountries",
    getBlogs :'users/allBlogs',  
    getBlogData :'users/blogById?id=', 


  
   
}

export default ApiNames;