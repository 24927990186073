import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Layout from './Pages/Layout/Layout';
import { Route, Routes } from 'react-router-dom';
import Landing from './Pages/Landing/Landing';
import Aboutus from './Pages/Aboutus/Aboutus';
import Ourproducts from './Pages/Ourproducts/Ourproducts';
import Contactus from './Pages/Contactus/Contactus';
import Sustainability from './Pages/Sustainability/Sustainability';
import Faqpage from './Pages/Faqpage/Faqpage';
import Latestinnovations from './Pages/Latestinnovations/Latestinnovations';
import ProductLaunch from './Pages/ProductLaunch/ProductLaunch';
import AllBlogs from './Pages/AllBlogs/AllBlogs';
import BlogData from './Pages/BlogData/BlogData';

function App() {
  
  return (
  <>
  <Routes>
        <Route path="/" element={<Layout />}>
        <Route index element={<Landing />} />
          <Route path="/landing" element={<Landing />} />
          <Route path="/about-us" element={<Aboutus />} />
          <Route path="/our-products" element={<Ourproducts />} />
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/sustainability-Initiatives" element={<Sustainability />} />
          <Route path="/faq" element={<Faqpage />} />
          <Route path="/latest-innovations" element={<Latestinnovations />} />
          <Route path="/productlaunch" element={<ProductLaunch />} />
          <Route path="/allblogs" element={<AllBlogs/>} />
          <Route path="/bloginfo/:id" element={<BlogData/>} />
          <Route path="*" element={<Landing />} />
        </Route>
      
      </Routes>
  </>
  );
}

export default App;
