import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { NavLink, useLocation } from "react-router-dom";


const Sustainability = () => {
    const [activeImage, setActiveImage] = useState(0);
    const location = useLocation();

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);

    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    const images = [
        "/images/landing/ani-1.webp",
        "/images/landing/ani-2.webp",
        "/images/landing/ani-3.webp",
        "/images/ourproducts/products/5.webp",
        // "/images/landing/ani-5.webp",
        "/images/landing/ani-11.webp",
        "/images/landing/ani-6.webp",
        "/images/ourproducts/products/11.webp",
        "/images/landing/ani-8.webp",
        "/images/landing/ani-9.webp",
        "/images/ourproducts/products/16.webp",

    ];

    const Header = [
        "rightACRYLIC",
        "rightBOND",
        "Solid Surface",
        "rightLAM",
        "Wall Panels [RMD]",
        "rightPC",
        "rightCAL",
        "rightPET",
        "rightABS",
        "rightCHANNELIUM",

    ];
    const Subheader = [
        "For our recycled sheets, we use MMA for our cast sheets and recycled PMMA for extrusion-grade",
        "Our recycled composite PE material and recycled and recyclable aluminum give your buildings a functional and modern appearance. They're also tested and proven to be highly resistant to fire and the spread of flames",
        "Furnish your kitchen with long-lasting, eco-friendly solid surface countertops",
        "Our laminate products are excellent for indoor and outdoor use. Plus, they're completely recyclable.",
        "Our wall panels are made using recycled polymers (PP) with a revolutionary laminate with BOPP and PET.",
        "Our polycarbonate sheets are 100% recyclable and highly resistant to impact electricity and heat",
        "100% recyclable and self-extinguishing flammability vinyl perfect for your promotional graphics",
        "Made with Recycled PET and gives excellent mechanical strength and resistance to creep",
        "Made using recycled ABS and virgin blend.",
        "Recyclable flexible alloy suitable for advertising boards",

    ];

    const handleImageClick = (index) => {
        setActiveImage(index);
    };

    const handlePrev = () => {
        setActiveImage(
            (prevActiveImage) => (prevActiveImage - 1 + images.length) % images.length
        );
    };

    const handleNext = () => {
        setActiveImage((prevActiveImage) => (prevActiveImage + 1) % images.length);
    };
    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            const id = hash.substring(1);
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({
                    behavior: "smooth"
                });
            }
        }

    }, [location.hash])
    return (
        <>
            <Helmet>
                <title>
                    Sustainability Initiatives
                </title>
            </Helmet>
            <div className='sustainability-content'>
                <div className="OurProducts-section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-11 col-lg-10">
                                <div className="maintitle-section">
                                    <h3 className="main-title">Access our eco-friendly offerings for commercial and residential use</h3>
                                    <p className="main-subtitle">
                                        From leading gas stations, banks, shopping emporiums, and other enterprises, Just Right’s advertising and signage media ooze quality and sustainability.
                                    </p>
                                    <p className="main-subtitle">
                                        All products are developed using the most advanced cutting-edge technology.
                                    </p>
                                    <p className="main-subtitle">
                                        Justright Inc. & Renov8 Polymers specializes in providing high-quality sustainable polymer solutions tailored to meet the diverse needs of industries worldwide. With a commitment to eco-friendly practices and innovation, we focus on manufacturing and processing key polymers to cater to various applications. Whether you need a small batch of a few hundred kilograms or full truckloads, we deliver reliable, efficient, and customized solutions.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div className="sustainableswiper-page">
                    <div className="container">
                        <div className="image-box">
                            {images.map((src, index) => (
                                <div
                                    key={index}
                                    className={`imgee-top ${activeImage === index ? "active" : ""}`}
                                    onClick={() => handleImageClick(index)}
                                >
                                    <img className="imges" src={src} alt={`image-${index}`} />
                                </div>
                            ))}
                        </div>
                        <div className={`content-box ${activeImage === activeImage ? "active" : ""}`}>
                            <div className="content-data">
                                <p className="box-headr">{Header[activeImage]}</p>
                                <p className="box-para">{Subheader[activeImage]}</p>

                                <div className="btns-top">
                                    <button className="prev-btn" onClick={handlePrev}>
                                        <i className="fas fa-angle-left"></i>
                                    </button>
                                    <button className="next-btn" onClick={handleNext}>
                                        <i className="fas fa-angle-right"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-top d-md-none">
                            <Swiper
                                modules={[Navigation]}
                                slidesPerView={1}
                                autoplay={false}
                                loop={true}
                                navigation={true}
                            >
                                {images.map((src, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="responsive-swipertop">
                                            <img className="imgess" src={src} alt={`image-${index}`} />
                                            <div className="content2-box">
                                                <div>

                                                    <p className="box-headr">{Header[index]}</p>
                                                    <p className="box2-para">{Subheader[index]}</p>
                                                    {/* <a className="learn2-moree" href="">
                                                        Learn More
                                                        <i className="fa-solid fa-arrow-right arow-right"></i>
                                                    </a> */}
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>


                    </div>
                </div>
                <div className="OurProducts-section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <div className="maintitle-section">
                                    <h3 className="main-title main-title2">Our Products</h3>
                                    <p className="main-subtitle">
                                        We offer a carefully selected range of premium polymer materials in both compound and regrind forms, ensuring versatility and top-tier quality for our customers.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <div className="maintitle-section">
                                    <h3 className="main-title main-title2">Closing the loop in the plastics industry</h3>
                                    <p className="main-subtitle">
                                        Just Right’s recycling programs are aimed at environmental sustainability, with incentives for partners contributing to a greener future
                                    </p>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="sidecntent-section">
                                    <h3 className="sidecntent-head">Renov8 Plastic Industry</h3>
                                    <p className="sidecntent-subhead">Circular economy system focused on climate change</p>
                                    <p className="sidecntent-txt">The Renov8 state-of-the-art recycling facilities foster a circular economy for the industry by sorting, processing, recreating, and re-sourcing plastics to achieve sustainable development goals.</p>
                                </div>
                                <div className="sidecntent-section">
                                    <h3 className="sidecntent-head">JustRight GiveBack Initiative</h3>
                                    <p className="sidecntent-subhead">Elevate your plastic waste into a legacy of sustainability</p>
                                    <p className="sidecntent-txt sidecntent-txt2">Partner with us in creating refined, sustainable products as we transform your plastic waste into premium recycled sheets and resins</p>
                                    <NavLink className="sidecntent-bt" to="/contact-us">Contact us to get valuation for your scrap <i className="fa fa-arrow-right cstmarw" aria-hidden="true" ></i></NavLink>
                                    <NavLink className="sidecntent-bt3" to="/contact-us">Get valuation for your scrap <i className="fa fa-arrow-right cstmarw" aria-hidden="true" ></i></NavLink>
                                </div>
                                <div className="sidecntent-section">
                                    <h3 className="sidecntent-head">Energy Efficiency Practices</h3>
                                    <p className="sidecntent-subhead">Low Energy Consumption Motors</p>
                                    <p className="sidecntent-txt">Our servo motors have a high power efficiency of up to 90%, which helps us save energy and lose less heat in our manufacturing processes.</p>
                                    <NavLink className="sidecntent-bt sidecntent-bt1" to="/our-products">Check out more of our energy-efficient products <i className="fa fa-arrow-right cstmarw" aria-hidden="true" ></i></NavLink>
                                    <NavLink className="sidecntent-bt3" to="/our-products">Check out more <i className="fa fa-arrow-right cstmarw" aria-hidden="true" ></i></NavLink>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='sidecntent-rightsecmain'>
                                    <div className="sidecntent-rightsec">
                                        <img className="rightsec-im" src="/images/ourproducts/image-1.webp" alt='no-data' />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="sidecntent-section">
                                    <h3 className="sidecntent-head mb-4 mt-4 text-center">Key Materials:</h3>
                                    <p className="sidecntent-subhead">PMMA (Polymethyl Methacrylate)</p>
                                    <p className="sidecntent-txt">Known for its exceptional clarity, weather resistance, and durability, ideal for applications such as signage, glazing, and displays.</p>
                                    <p className="sidecntent-subhead">LDPE (Low-Density Polyethylene)</p>
                                    <p className="sidecntent-txt">A flexible and durable polymer perfect for packaging films, liners, and extrusion applications.</p>
                                    <p className="sidecntent-subhead">HDPE (High-Density Polyethylene)</p>
                                    <p className="sidecntent-txt">Recognized for its strength and rigidity, used extensively in manufacturing containers, piping, and industrial applications.</p>
                                    <p className="sidecntent-subhead">ABS (Acrylonitrile Butadiene Styrene)</p>
                                    <p className="sidecntent-txt">A robust material with excellent impact resistance, widely applied in automotive, electronics, and consumer products.</p>
                                    <p className="sidecntent-subhead">PC (Polycarbonate)</p>
                                    <p className="sidecntent-txt">A high-performance thermoplastic valued for its transparency, toughness, and versatility across industries.</p>
                                    <p className="sidecntent-subhead">PP (Polypropylene)</p>
                                    <p className="sidecntent-txt">Lightweight yet strong, offering excellent chemical resistance and used in applications such as packaging, automotive parts, and textiles.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="OurProducts-section">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-11 col-lg-10">
                                    <div className="maintitle-section">
                                        <h3 className="main-title">Sustainable Recycling Services</h3>
                                        <p className="main-subtitle">
                                            As leaders in the plastics industry, we prioritize sustainability by transforming plastic waste into reusable raw materials. Reducing dependcy on new plastic generation. Our comprehensive recycling process ensures near-zero waste and maximizes resource recovery.
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className="sidecntent-section">
                                        <h3 className="sidecntent-head mb-4 text-center">Our Recycling Process Includes:</h3>
                                        <p className="sidecntent-txt"><strong>Collection:</strong> Loose, baled, or contained plastics at our facility.</p>
                                        <p className="sidecntent-txt"><strong >Sorting:</strong>  Grouping materials by type and grade using advanced technologies.</p>
                                        <p className="sidecntent-txt"><strong >Processing:</strong>  Rigorous steps including shredding, granulation, washing, sieving, blending, and pelletizing ensure high-quality output.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-11 col-lg-10">
                                <div className="maintitle-section">
                                 
                                    <p className="main-subtitle">
                                    At Justright Inc. & Renov8 Polymers Industries, we are not just manufacturers; we are partners in sustainable innovation, providing eco-friendly solutions without compromising on quality.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bgclr-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="bgclr-sectionleft">
                                    <h3 className="bgclr-sectionhead">Boosting the local economy and promoting Made in UAE products</h3>
                                    <p className="bgclr-sectiontxt">Just Right sees the opportunity to grow its business in JAFZA as a way of engaging with the local community and contributing positively to the UAE economy</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="bgclr-sectionright">
                                    <h3 className="bgclrsectionright-head">Business Expansion in the Jebel Ali Free Zone Authority (JAFZA)</h3>
                                    <p className="bgclrsectionright-txt">Through its strong commitment to further strengthen its association with JAFZA, Just Right has enhanced its community engagement policy by:</p>
                                    {/* <div className="bgclr-section2">
                                        <img className="bgclr-section2im" src="/images/ourproducts/check.png" />
                                        <p className="bgclr-section2txt">Increasing investment by AED 200M, taking its total investment to AED 315M</p>
                                    </div> */}
                                    <div className="bgclr-section2">
                                        <img className="bgclr-section2im" src="/images/ourproducts/check.png" alt='no-data' />
                                        <p className="bgclr-section2txt">Creating additional employment opportunities for about 150-180 staff.</p>
                                    </div>
                                    <div className="bgclr-section2">
                                        <img className="bgclr-section2im" src="/images/ourproducts/check.png" alt='no-data' />
                                        <p className="bgclr-section2txt">Contributing to the local economy by way of increased trade and consumer consumption</p>
                                    </div>
                                    <div className="bgclr-section2">
                                        <img className="bgclr-section2im" src="/images/ourproducts/check.png" alt='no-data' />
                                        <p className="bgclr-section2txt">Making further investments in the manufacturing of Green Plastic and promoting MADE IN UAE products</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="imgsection">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-11">
                                <div className="sectionim">
                                    <img src="/images/ourproducts/image.webp" alt='no-data' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="OurProducts-section OurProducts-section3">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <div className="maintitle-section">
                                    <h3 className="main-title main-title2">Our Recognitions and Achievements</h3>
                                    <p className="main-subtitle">
                                        At JustRight, our dedication to excellence has been recognized through prestigious awards and certifications. We take pride in our commitment to quality, sustainability, and innovation, as reflected in the following achievements
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="subsection">
                                    <h3 className="subsection-head">ISO 9001 Certification</h3>
                                    <p className="subsection-txt">Demonstrating our adherence to international standards for quality management systems, ensuring consistent delivery of superior products and services.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="subsection">
                                    <h3 className="subsection-head">Green Industry Certification</h3>
                                    <p className="subsection-txt">Recognizing our environmentally responsible practices and commitment to sustainable manufacturing.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="subsection">
                                    <h3 className="subsection-head">Innovation Award</h3>
                                    <p className="subsection-txt">Honoring our pioneering work in developing cutting-edge materials and solutions that redefine industry standards.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='successstories-content' >
                    <div className='container' id='successstory'>
                        <h1 className='see-content'>See how we’ve excelled in achieving our sustainability goals in manufacturing</h1>
                        <p className='success-para'>At Justright, we prioritize innovative practices and renewable energy to meet and exceed our environmental targets.</p>

                    </div>
                    <div className='success-data' >
                        <div className="container">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='success-topp'>
                                        <div className='success-content'>
                                            <h1 className='success-head'>Join us in building a sustainable future for our communities</h1>
                                            <p className='success-commitmentpara'>At Just Right, our commitment to sustainability has seen us actively engage with locals through educational initiatives, source materials responsibly through local suppliers and prioritizing workforce wellbeing, inspiring other businesses to adopt eco-conscious practices.</p>
                                            <button onClick={scrollToTop} className='success-btn'>Join our sustainable community<i class="fas fa-arrow-right cstmarw"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='success-image'>
                                        <img className='success-img' src='/images/successstories/success.webp' alt='no-data' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sustainability