// toastrService.js

import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

// Configure Toastr options globally
toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: true,
  progressBar: true,
  positionClass: 'toast-top-right',
  preventDuplicates: false,
  onclick: null,
  showDuration: '300',
  hideDuration: '1000',
  timeOut: '5000',
  extendedTimeOut: '1000',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
};

export const showSuccessToast = (message, title) => {
  toastr.success(message, title);
};

export const showErrorToast = (message, title) => {
  toastr.error(message, title);
};

export const showInfoToast = (message, title) => {
  toastr.info(message, title);
};

export const showWarningToast = (message, title) => {
  toastr.warning(message, title);
};

// You can define more methods as needed for different types of notifications
